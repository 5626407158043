#ar-button-2 {
    background-image: url(./assets/360.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    cursor: "pointer";
    background-color: #fff;
    white-space: nowrap;
    bottom: 16px;
    padding: 0px 16px 0px 40px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color:#4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #DADCE0;
  }
  #ar-button:active {
    background-color: #E8EAED;
  }
  
  #ar-button:focus {
    outline: none;
  }
  
  #ar-button:focus-visible {
    outline: 1px solid #4285f4;
  }

  @keyframes circle {
    from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
    to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
  }
  
  @keyframes elongate {
    from { transform: translateX(100px); }
    to   { transform: translateX(-100px); }
  }
  #model-viewr {
    /* Your default styles here */
    height: 500px !important;
    width: 100% !important;
  }

  @media screen and (max-width: 769px) {
    #model-viewr {
      height: 400px !important;
     
    }
}

@media screen and (max-width: 300px) {
    #model-viewr {
      height: 200px !important;
     
    }
}
  
model-viewer > #ar-prompt {
    /* position: absolute;
    left: 50%;
    bottom: 60px; */
    animation: elongate 2s infinite ease-in-out alternate;
    display: none;
  }
  
  model-viewer[ar-status="session-started"] > #ar-prompt {
    display: block;
  }
  
  model-viewer > #ar-prompt > img {
    animation: circle 4s linear infinite;
  }

.modal{
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #00000080;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
}
.modal .modal-overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
.modal-content{
  max-width: 1545px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 23px #00000040;
  max-height: 890px;
  height: 100%;
  border-radius: 21px;
  position: relative;
  z-index: 101;
  padding: 0px 30px;
}
.modal-content iframe{
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto; /* Adjust margin as needed */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
